.img-responsive {
  width: 100%;
  height: auto;
}

$alignments: center, left, right;

@each $alignment in $alignments {
  .text-#{$alignment} {
    text-align: $alignment;
  }
}

.flex-container {
  display: flex;
}

$text-colors:
  'dk-green' $dk-green,
  'md-green' $md-green,
  'white' $white,
  'black' $black;

@each $name, $color in $text-colors {
  .text-#{$name} {
    color: $color;
  }
}

$text-weights:
  'light' $light,
  'medium' $medium,
  'bold' $bold;

@each $name, $weight in $text-weights {
  .#{$name} {
    font-weight: $weight;
  }
}