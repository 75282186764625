@import "../../styles/util/util";

.my-collection {
  display: flex;
  flex-direction: column;
  align-items: center;

  .create-header {
    padding: rem-calc(73) 0 rem-calc(88);
  }

  .create-body-text {
    width: 95%;
    max-width: rem-calc(480);
    margin-bottom: rem-calc(60);

    .h2 {
      margin-bottom: rem-calc(30);
    }
  }

  .create-cta {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .h2 {
      margin-bottom: rem-calc(17);
    }
  }

  .pose-examples {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: rem-calc(48);

    .pose {
      width: rem-calc(135);
      position: relative;
      margin: 0 rem-calc(10);

      img {
        position: relative;
        z-index: 2;
      }
    }
  }
}

.collection-list {
  .collection-list__inner > .title-container {
    margin-bottom: rem-calc(45);
    padding-top: rem-calc(73);

    .h1 {
      margin-bottom: rem-calc(22);
    }

    .create-text {
      justify-content: center;
      align-items: center;
      display: none;

      @include respond($bp-md) {
        display: flex;
      }

      .fab-button {
        height: 2.5rem;
        width: 2.5rem;
        padding-top: 2px;
        margin-right: 1rem;
      }
    }
  }

  .list-container {
    margin: 0 auto;
    max-width: rem-calc(1300);
    width: 100%;
    padding: 0 rem-calc(24);
    padding-bottom: rem-calc(100);

    @include respond($bp-md) {
      width: 95%;
      padding-bottom: 0;
    }
  }

  .sequences-table {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include respond($bp-md) {
      display: table;
      table-layout: auto;
    }

    thead {
      top: $header-height;
      z-index: 1;

      @include respond-max($bp-mob-max) {
        display: flex;
        width: 100%;
      }

      th:nth-of-type(1),
      th:nth-of-type(4) {
        @include respond-max($bp-mob-max) {
          width: 50%;
        }
      }

      th:nth-of-type(4) {
        p.body.body--small {
          @include respond-max($bp-mob-max) {
            justify-content: flex-end;
          }
        }
      }
    }

    td {
      &:not(.sequence-name):not(.sequence-date):not(.actions) {
        @include respond-max($bp-mob-max) {
          display: none;
        }
      }
    }

    th {
      &:not(:nth-of-type(1)):not(:nth-of-type(4)) {
        @include respond-max($bp-mob-max) {
          display: none;
        }
      }
    }

    th,
    td {
      text-align: center;
      border-bottom: 1px solid $border-alt;
    }

    td {
      padding: rem-calc(18) 0;
      width: 11%;
      vertical-align: middle;

      &.sequence-name {
        text-align: left;
      }
    }

    th {
      padding-bottom: rem-calc(8);
    }

    tbody tr {
      @include respond-max($bp-mob-max) {
        padding: rem-calc(24) rem-calc(8) rem-calc(11);
        background-color: rgba($white, 0.5);
        border-bottom: rem-calc(1) solid $neutral-light-grey;
        margin-bottom: rem-calc(24);
      }
    }

    tr {
      @include respond-max($bp-mob-max) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex: 0 0 100%;

        td {
          border-bottom: none;
        }

        td.sequence-name,
        td.sequence-date {
          display: flex;
          align-items: center;
        }

        td.sequence-name {
          flex: 1 1 65%;
          padding: 0 rem-calc(7) 0 0;
          max-width: calc(100% - #{rem-calc(80)});

          .h2 {
            text-overflow: ellipsis;
            overflow: hidden;
          }

          a.text-link {
            width: auto;
            max-width: 100%;
            white-space: nowrap;
          }
        }

        td.sequence-date {
          flex: 1 0 rem-calc(80);
          padding: 0 0;
          justify-content: flex-end;
        }

        td.actions {
          display: flex;
          flex: 0 0 100%;
          margin-top: rem-calc(24);
          padding-bottom: 0;
          padding-top: 0;

          .sequence-actions {
            display: flex;
            width: 100%;
            justify-content: space-around;
            padding-top: rem-calc(11);

            .action-container {
              display: flex;
              flex-direction: column;
              align-items: center;

              .fab-button {
                width: rem-calc(32);
                height: rem-calc(32);
                margin-bottom: rem-calc(8);

                svg {
                  transform: scale(1.5);
                }
              }

              .btn-label {
                font-weight: $light;
                color: $dk-brown;
                font-size: rem-calc(14);
              }

              &.mobile-hide {
                @include respond-max($bp-md-max) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .sequence-name {
      width: auto;
      white-space: normal;

      .text-link {
        text-decoration: none;
        border-bottom: rem-calc(1px) solid $text-peach;
        color: $text-peach;

        &:hover {
          text-decoration: none;
        }

        &.mobile {
          @include respond($bp-md) {
            display: none;
          }
        }

        &.desktop {
          display: none;
          @include respond($bp-md) {
            display: inline-block;
          }
        }
      }

      .h2 {
        text-transform: initial;
        font-size: rem-calc(20);
        font-weight: $medium;
      }

      &:hover {
        @include respond($bp-md) {
          text-decoration: underline;
        }
      }
    }

    .sequence-actions {
      display: flex;
      justify-content: space-around;

      .action-container {
        .btn-label {
          @include respond($bp-md) {
            display: none;
          }
        }

        button, .download-container, .play-container {
          margin: 0 rem-calc(8);
        }

        a {
          color: inherit;
        }
      }
    }
  }
}
