@import '../../styles/util/util';

.share-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: rem-calc(20);

  .field.text-field {
    margin-bottom: 0;
  }

  .email-container {
    flex: 0 0 100%;
    margin-bottom: rem-calc(24);

    .multi-value-text-input.field {
      label {
        order: 1;
        flex: 1 1 70%;

        @include respond($bp-md) {
          flex: 1 1 75%;
        }
      }

      .input-button {
        order: 2;
        flex: 0 1 auto;
        min-width: rem-calc(100);
        margin-top: rem-calc(24);
      }

      .fab-button {
        order: 2;
        flex: 0 0 rem-calc(80);
        align-self: center;
        margin-left: rem-calc(16);
        // min-width: rem-calc(100);
        // margin-top: rem-calc(24);
      }

      .errors {
        width: 100%;
        order: 3;
        text-align: left;
        padding-top: 0;
      }
    }
  }

  .url-container {
    flex: 0 0 100%;
    margin-bottom: rem-calc(24);

    @include respond($bp-md) {
      flex: 0 0 50%;
      padding-right: rem-calc(24);
    }
  }

  .qr-container {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem-calc(24);

    .body {
      width: calc(100% - 100px);
      padding-left: 1rem;
      text-align: left;
    }

    @include respond($bp-md) {
      flex: 0 0 50%;
      padding-left: rem-calc(24);
    }
  }

  .submit-container, .download-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .h4 {
      margin-left: rem-calc(7);
    }
  }

  .submit-container {
    width: rem-calc(80);
    // position: absolute;
    // bottom: rem-calc(47);
    // left: 50%;
    // transform: translateX(-50%);
  }

  .download-container {
    width: 100%;
    // position: absolute;
    // bottom: rem-calc(47);
    // left: 50%;
    // transform: translateX(-50%);
  }
}
