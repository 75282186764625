@import "../../styles/util/util.scss";

.button {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
}

.text-button {
  color: $text-green;
  font-weight: $bold;
  font-size: rem-calc(16);
  text-decoration: none;
  outline: none;
  border: none;
  background-color: transparent;
  min-width: rem-calc(150);
  padding: rem-calc(7) rem-calc(25);
  white-space: nowrap;
  border-radius: rem-calc(15);
  transition: 0.25s background-color ease-out, 0.25s color ease-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
    width: auto;

    .stroke-g {
      stroke: $white;
    }

    .fill-g {
      fill: $white;
    }
  }

  &.filled {
    background-color: $peach;
    color: $white;

    &:hover {
      background-color: $peach;
      color: $white;
    }

    &.white {
      background-color: $white;
      color: $peach;
    }
  }

  &.square {
    color: $white;
    background-color: $peach;
    font-weight: $bold;
    text-transform: uppercase;
    border-radius: 0;
    font-size: rem-calc(14);
    height: rem-calc(42);
    align-items: center;
    min-width: rem-calc(130);
  }

  &.error {
    background-color: $error-red;

    &:hover {
      background-color: rgba($error-red, 0.8);
    }
  }

  &.outlined {
    background-color: transparent;
    color: $peach;
    border: rem-calc(2) solid $peach;

    &.error {
      border-color: $error-red;
    }
  }

  .button-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-right: rem-calc(7);
  }

  &:hover {
    background-color: $peach;
    color: $white;

    svg {
      g.fill-g {
        fill: $white;
      }

      g.stroke-g {
        stroke: $white;
      }
    }
  }

  &.active {
    background-color: $peach;
    color: $white;
  }
}

.icon-button {

  svg,
  svg g {
    fill: transparent;
    transition: 0.25s fill ease-out;
  }

  &:hover {
    svg {

      g.fill-g {
        fill: rgba($peach, 0.8);
      }

      g.stroke-g {
        stroke: rgba($dk-green, 0.8);
      }
    }
  }

  &.active {
    svg {
      g.fill-g {
        fill: $peach;
      }

      g.stroke-g {
        stroke: $dk-green;
      }
    }
  }
}

.fab-button {
  height: rem-calc(48);
  width: rem-calc(48);
  border-radius: 50%;
  background-color: $peach;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s background-color ease-out;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: relative;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.35;
  }

  &:hover {
    .button-tooltip {
      opacity: 1;
    }
  }

  .button-tooltip {
    position: absolute;
    width: rem-calc(100);
    font-size: rem-calc(12);
    background-color: $timeline-grey;
    padding: rem-calc(5) rem-calc(7);
    border-radius: rem-calc(5);
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    opacity: 0;
    transition: 0.25s opacity ease-out;
    z-index: 2;
  }

  &.variant-white {
    background-color: $white;

    svg {
      .fill-g {
        fill: $peach;
      }

      .stroke-g {
        stroke: $peach;
      }
    }

    &:hover {
      background-color: rgba($white, 0.7);
    }
  }

  &.fab--large {
    height: rem-calc(80);
    width: rem-calc(80);
  }

  &.fab--small {
    height: rem-calc(40);
    width: rem-calc(40);
    position: relative;

    svg {
      width: rem-calc(38);
      max-width: rem-calc(38);
      max-height: unset;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  svg {
    max-height: rem-calc(32);
    max-width: rem-calc(32);

    .stroke-g {
      stroke: $white;
    }

    .fill-g {
      fill: $white;
    }
  }

  &:hover {
    background-color: rgba($peach, 0.8);
  }
}
