
// brand colors
$white: #fff;
$buff: #DED9D3;
$peach: #e58061;
$dk-green: #2e3d1a;
$md-green: #78824a;
$lt-green: #7e9a67;
$lt-blue: #ceebff;
$black: #1F1B1B;
$text-black: #000;
$text-green: $dk-green;
$text-peach: $peach;
$border: rgba(0, 0, 0, 0.1);
$border-alt: #e5e5e5;
$timeline-grey: #cec9c4;
$box-shadow-color: rgba(59, 49, 41, 0.1);
$beige: #f7ede8;
$box-shadow: rgba(0, 0, 0, 0.16);
$site-dk-green: #2e3d1a;
$neutral-light-grey: #d5d5d5;
$dk-brown: #302020;
$error-red: #cc0000;

$input-bkg: #f9f9f9;

$header-height: rem-calc(62);
$header-height-mobile: rem-calc(56);

$input-box-shadow: 0 rem-calc(4) rem-calc(4) 0 $box-shadow-color;

// NEW COLORS 10/2023
$text-light-grey: #808080;
$grey-border-3: #999;
$grey-border-card: #CFD8D6;
$text-grey: #575959;
$input-panel-bg: #F8F8F8;
$grey-placeholder: #B5B5B5;
$black-alt: #101010;
$dk-green-alt: #3C5041;
$grey-border-4: #E4E4E4;

// fonts
$primary-font: 'Montserrat', 'Arial', sans-serif;
$light: 300;
$medium: 400;
$medium-bold: 500;
$semi-bold: 600;
$bold: 700;

// breakpoints
$bp-phab: 600px;
$bp-sm: 992px;
$bp-sm-mob-max: 700px;
$bp-mob-max: 991px;
// $bp-md: 1024px;
$bp-md: 992px;
$bp-md-max: 1365px;
$bp-lg: 1366px;
