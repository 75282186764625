@import '../../styles/util/util';

.start-screen {
  padding-top: rem-calc(180);

  .auth-container {
    margin: 0 auto;
    max-width: rem-calc(400);
    padding-top: rem-calc(39);
  }

  .auth-container__inner {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.invalid-subscription {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      justify-content: space-evenly;

      .text-button {
        width: 40%;
      }
    }
  }

  .auth-header {
    margin-bottom: rem-calc(30);
    text-align: center;

    strong {
      font-weight: $bold;
    }
  }
}
