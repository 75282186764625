@import "../../styles/util/util";

// .modal {
//   background-image: url('../../images/texture-page@2x.jpg');
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center center;
//   padding-top: $header-height;
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   opacity: 0;
//   pointer-events: none;
//   transition: 0.25s opacity ease-out;
//   z-index: 11;
//
//   &.active {
//     opacity: 1;
//     pointer-events: auto;
//
//     .modal-content {
//       transform: scale(1);
//     }
//   }
//
//   .modal__inner {
//     display: flex;
//     flex-direction: column;
//     padding: rem-calc(40) 0;
//     align-items: center;
//   }
//
//   .modal-content {
//     height: rem-calc(630);
//     width: rem-calc(610);
//     background: url('../../images/pose_main_default.png') no-repeat center center;
//     background-size: cover;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     transform: scale(0.95);
//     transition: 0.25s transform ease-out;
//   }
//
//   .modal-content__inner {
//     max-width: rem-calc(400);
//     width: 100%;
//     margin: rem-calc(107) auto 0;
//     height: 100%;
//     position: relative;
//   }
//
//   .modal-title-container {
//     display: flex;
//     align-items: center;
//     margin-bottom: rem-calc(40);
//     padding: 0 0;
//
//     h1 {
//       margin-right: 1rem;
//     }
//   }
// }

.ytl-modal {
  max-width: rem-calc(640);
  width: 100%;
  padding: rem-calc(24);
  box-shadow: 0 0 rem-calc(16) 0 rgba(0, 0, 0, 0.16);
  background-color: $white;
  border-radius: rem-calc(16);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100lvh - #{rem-calc(48)});
  overflow-y: scroll;

  .modal-title {
    display: flex;
    margin-bottom: rem-calc(48);

    h1 {
      font: {
        size: rem-calc(32);
        weight: $bold;
      }

      color: $text-green;
      flex: 1 1 90%;
      margin-right: 1rem;
    }

    .fab-button {
      flex: 0 0 rem-calc(48);
    }
  }

  h3 {
    font: {
      size: rem-calc(20);
      weight: $bold;
    }

    margin-bottom: rem-calc(8);
  }
}

.MuiBackdrop-root {
  background-color: rgba(247, 237, 232, 0.5) !important; // important overrides inline style
}
