.primary-btn {
  background-color: $dk-green;
  color: $white;
  font-weight: $bold;
  font-size: rem-calc(16);
  padding: rem-calc(5) rem-calc(15);
  text-align: center;
  border-radius: rem-calc(15);
  min-width: rem-calc(150);
  cursor: pointer;
  transition: 0.25s background-color ease-in-out;
  outline: none;
  border: none;

  &:hover {
    background-color: $md-green;
  }
}
