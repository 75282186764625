.field {

  &.text-field {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem-calc(20);
    flex: 0 0 100%;

    .input {
      height: rem-calc(44);
      color: black;
      width: 100%;
      border-radius: 0;
      border: 1px solid #e4e4e4;
      padding: 0 rem-calc(10);
      box-shadow: $input-box-shadow;
    }

    label {
      margin-bottom: rem-calc(10);
    }

    &.--with-button {

      .input-container {
        display: flex;
        flex: 0 0 100%;
      }

      .input {
        flex: 1 1 100%;
      }

      .input-button {
        flex: 0 1 auto;
        background-color: $peach;
        height: rem-calc(44);
        font-size: rem-calc(14);
        font-weight: $bold;
        text-transform: uppercase;
        color: $white;
        border-radius: 0px;
        outline: none;
        min-width: rem-calc(100);
        box-shadow: none;
        appearance: none;
        margin: 0 0;
        border: none;
        cursor: pointer;
        transition: 0.25s background-color ease-out;

        &:hover {
          background-color: rgba($peach, 0.8);
        }

        svg {
          .fill-g {
            fill: $white;
          }
          .stroke-g {
            stroke: $white;
          }
        }
      }
    }
  }

  input {
    font-family: $primary-font;
  }

  > .errors {
    padding-top: 0.5rem;
    padding-bottom: 0;
  }
}

input[type="text"],
input[type="number"],
input[type="password"],
textarea {
  color: black;
  width: 100%;
  border-radius: 0;
  border: 1px solid #999;
  padding: 0 rem-calc(10);
  font-family: $primary-font;

  &:disabled,
  &[disabled="true"] {
    background-color: $white;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &.duration-input {
    font-size: 1rem;
  }

  &.number-input {
    font-size: 2rem;
    height: rem-calc(50);
    width: rem-calc(65);
    -moz-appearance: textfield;
    padding-bottom: rem-calc(3);
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

textarea {
  width: 100%;
  min-width: rem-calc(400);
  min-height: rem-calc(200);
  // background-color: rgba($white, 0.3);
  padding: rem-calc(15) rem-calc(10);
  font-size: 1rem;
  color: black;
  width: 100%;
  border-radius: 0;
  border: 1px solid #e4e4e4;
  box-shadow: $input-box-shadow;
  line-height: 1.4;
}

.invisible-input {
  background-color: transparent;
  border-color: transparent;
  transition: 0.25s background-color ease-out;

  &:focus {
    background-color: $white;
    border-color:#999;
    outline: none;
  }
}

.input-radio {
  margin-right: rem-calc(34);

  label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    span {
      font-weight: $medium;
      font-size: 1rem;
      color: $text-black;
    }
  }

  input {
    margin: 0 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  .custom-radio {
    height: rem-calc(20);
    width: rem-calc(20);
    background-color: $peach;
    border-radius: 50%;
    margin-right: rem-calc(7);
    position: relative;

    &::after {
      content: "";
      width: rem-calc(8);
      height: rem-calc(8);
      border-radius: 50%;
      background-color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }
  }

  input:checked + .custom-radio::after {
    display: block;
  }
}

.radio-container {
  display: flex;
}
