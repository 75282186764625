@import '../../styles/util/util';

form.sign-in {
  display: flex;
  flex-wrap: wrap;

  .submit-button {
    display: flex;
    flex: 0 0 100%;
    justify-content: flex-end;
  }

  .form-link {
    display: flex;
    flex: 0 0 100%;
    margin-bottom: rem-calc(20);
  }

  .directions.h4 {
    margin-bottom: rem-calc(7);

    + .field {
      margin-top: rem-calc(23)
    }
  }

  .directions.body {
    margin-bottom: rem-calc(30);
  }

  .submit-button.send-code,
  .submit-button.new-password {
    margin-top: rem-calc(30);
  }

  .submit-button.send-code,
  .submit-button.submit-forgot-password {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a.text-link {
      font-size: rem-calc(14);
    }
  }

  .submit-button.submit-forgot-password {
    margin-bottom: 1.25rem;
  }

  .back-to-login {
    display: flex;
    // justify-content: center;
    width: 100%;

    a.text-link {
      font-size: rem-calc(14);
    }
  }

  .auth-error {
    margin-bottom: 1rem;
  }

  > .errors {
    margin-bottom: 1rem;
  }
}

.sign-up {
  margin: 0 auto;
  max-width: rem-calc(400);
  padding-top: rem-calc(39);

  .page-title-container {
    margin-bottom: rem-calc(57);
  }

  .submit-container {
    position: relative;
    display: flex;
    justify-content: flex-end;

    .form-link {
      position: absolute;
      left: 0;
      padding-top: 0.5em;
    }

    .text-button {
      padding-top: rem-calc(10);
      padding-bottom: rem-calc(10);
      border-radius: rem-calc(30);
    }
  }

  .user-image {
    margin-bottom: rem-calc(32);
    text-align: center;

    .image-thumbnail {
      width: rem-calc(150);
      height: rem-calc(150);
      position: relative;
      background-color: $peach;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto 1rem;

      svg,
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height:auto;

        @supports(object-fit: cover) {
          object-fit:cover;
          position:static;
          transform: translate(0, 0);
          height: 100%;
          width:100%;
        }
      }
    }
  }

  .state-message {
    text-align: center;
    margin-bottom: 1rem;
  }

  form > .errors {
    margin-bottom: 1rem;
  }

  .field .instructions {
    margin-bottom: rem-calc(16);
    line-height: normal;
  }
}

.confirm-change {
  .text-container {
    width: 90%;
    max-width: rem-calc(660);
    margin: 0 auto;
    padding-top: rem-calc(32);
  }

  .h1 {
    line-height: 1;
    margin-bottom: rem-calc(16);
  }

  .body {
    max-width: rem-calc(420);
    margin: 0 auto rem-calc(32);
  }

  .button-container {
    display: flex;
    justify-content: center;

    .text-button {
      margin: 0 rem-calc(7);
    }
  }
}

.select-billing-type,
.change-subscription {
  display: flex;
  flex-direction: column;

  .page-title-container {
    margin: rem-calc(32) 0;
    .h1 {
      line-height: 1;
    }
  }

  .directions {
    width: 90%;
    margin: rem-calc(32) auto;
    max-width: rem-calc(320);

    h4 {
      margin-bottom: rem-calc(12);
    }

    ol {
      list-style: decimal;
      margin-left: 1.5rem;

      li {
        line-height: normal;
        margin: rem-calc(4) 0;
      }
    }
  }

  .choices-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    max-width: rem-calc(320);

    .text-button {
      margin-bottom: rem-calc(7);

      &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }

      &.paypal {
        background-color: #ffc439;
      }
      & > img, & > svg {
        height: rem-calc(20);
        width: auto;
      }

      .MuiCircularProgress-root {
        width: rem-calc(20);
        height: rem-calc(20);
        margin-right: rem-calc(7);
      }
    }

    &.loading {
      .button, #paypal-container {
        display: none;
      }
    }
  }

  .message {
    margin: rem-calc(48) auto rem-calc(16);
    text-align: center;
    font-size: rem-calc(20);
    line-height: 1.2;
    width: 90%;
    max-width: rem-calc(320);

    @include respond($bp-md) {
      font-size: rem-calc(24);
      max-width: 90%;
    }

    .parenthetical {
      font-size: 80%;
    }
  }

  .bottom-message {
    text-align: center;
    position: fixed;
    bottom: rem-calc(56);
    line-height: 1.5;
    font-size: rem-calc(12);
    width: 100%;
    padding: 0 rem-calc(16) rem-calc(16);

    @include respond($bp-md) {
      bottom: 0;
      font-size: rem-calc(14);
    }
  }
}
