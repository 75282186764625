@mixin respond($width, $height: false) {
  @if $height {
    @media screen and (min-width: $width) and (min-height: $height) { @content; }
  } @else {
    @media screen and (min-width: $width) { @content; }
  }
}

@mixin respond-max($width) {
  @media screen and (max-width: $width) { @content; }
}

@mixin respond-height($height) {
  @media screen and (min-height: $height) { @content; }
}
@mixin respond-height-max($height) {
  @media screen and (max-height: $height) { @content; }
}

@mixin respond-zone($lowerWidth, $upperWidth){
  @media screen and (min-width: $lowerWidth) and (max-width: $upperWidth) { @content; }
}

@mixin respond-orientation-max($width, $orientation) {
  @media screen and (max-width: $width) and (orientation: $orientation) { @content; }
}

@mixin landscape {
  @media screen and (orientation: landscape) { @content; }
}
@mixin portrait {
  @media screen and (orientation: portrait) { @content; }
}

@mixin touch-query {
  @media (hover: none) {
    @content;
  }
}

@function rem-calc($size)
{
  $remSize: $size / 16;
  @return #{$remSize}rem;
}
@function em-calc($size)
{
  $emSize: $size / 16;
  @return #{$emSize}em;
}

@function in-calc($size)
{
  $ppi: 127;
  $inSize: $size / $ppi;
  @return #{$inSize}in;
}

@mixin in-conversion-block ($px, $property) {
  #{$property}: rem-calc($px);

  @media print {
    #{$property}: in-calc($px);
  }
}

@mixin pt-conversion-block($px, $property) {
  #{$property}: rem-calc($px);

  @media print {
    #{$property}: pt-calc($px);
  }
}

@function pt-calc($size)
{
  $remSize: round( $size / 16 * 7.5 * 2 ) / 2;
  @return #{$remSize}pt;
}

@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
     object-fit: $fit;
  @if $position {
	  -o-object-position: $position;
	     object-position: $position;
  	font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
  	font-family: 'object-fit: #{$fit}';
  }
}

@mixin center-absolute {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
