

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
a,
li,
label,
body,
html {
  font-family: $primary-font;
}

.h1 {
  font-size: rem-calc(36);
  line-height: rem-calc(28);
  color: $text-black;
  font-weight: $light;

  @include respond($bp-md) {
    font-size: rem-calc(46);
  }

  @media print {
    font-size: pt-calc(46);
    line-height: pt-calc(46);
  }
}

.h2 {
  font-size: rem-calc(28);
  line-height: rem-calc(31);
  color: $text-peach;
  font-weight: $light;
  text-transform: uppercase;

  @media print {
    font-size: pt-calc(28);
    line-height: pt-calc(31);
    color: $text-green;
  }
}

.h3 {
  font-size: rem-calc(16);
  line-height: rem-calc(21);
  color: $text-black;
  font-weight: $medium;

  @media print {
    font-size: pt-calc(16);
    line-height: pt-calc(21);
  }
}

.h4 {
  font-size: rem-calc(16);
  line-height: rem-calc(21);
  color: $text-green;
  font-weight: $bold;
  text-transform: uppercase;

  @media print {
    font-size: pt-calc(16);
    line-height: pt-calc(21);
  }
}

.h5 {
  font-size: rem-calc(14);
  line-height: rem-calc(17);
  color: $text-black;
  font-weight: $light;

  @media print {
    font-size: pt-calc(14);
    line-height: pt-calc(17);
  }
}

.body, .remark {
  font-size: rem-calc(16);
  line-height: 1.31;
  font-weight: $medium;
  color: $text-black;

  &.body--small {
    font-size: rem-calc(14);
    line-height: 1.36;
  }
}

.subtext {
  font-size: rem-calc(12);
  font-weight: $light;
  color: $white;
  line-height: 1;
}

label {
  font-weight: $bold;
  color: $text-black;
}

.bold {
  font-weight: $bold;
}

.text-link,
.text-link:visited,
.text-link:active,
.text-link:focus {
  color: $peach;
  font-weight: $light;
  border-bottom: 1px solid $peach;
  text-decoration: none;
  cursor: pointer;
}

.error {
  color: red;
}
