@import '../../styles/util/util';

$header-height-mobile: rem-calc(56);

.app-header {
  position: relative;
  padding: 0 rem-calc(20);
  background-color: $beige;
  border-bottom: 1px solid $border;
  width: 100vw;
  z-index: 999;

  @media print {
    display: none;
  }

  .app-header--signed-out {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $header-height-mobile;
    max-width: rem-calc(1688);

    @include respond($bp-md) {
      height: $header-height;
      padding: 0 rem-calc(40);
      margin: 0 auto;
    }

    .mobile-nav-toggle {
      @include respond($bp-md) {
        display: none;
      }
    }

    .site-nav {
      position: fixed;
      top: $header-height-mobile;
      width: 100vw;
      height: calc(100lvh - #{$header-height-mobile});
      left: 0;
      transform: translateX(100%);
      transition: 0.25s transform ease-out;

      @include respond($bp-md) {
        position: relative;
        top: unset;
        width: auto;
        height: auto;
        left: unset;
        transform: none;
      }

      &.active {
        pointer-events: auto;
        transform: translateX(0);

        .site-nav__inner {
          // transform: translateX(0);
          opacity: 1;
        }

        .green-bg {
          opacity: 1;
        }
      }
    }

    .green-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($site-dk-green, 0.84);
      opacity: 0;
      transition: 0.25s opacity ease-out;

      @include respond($bp-md) {
        display: none;
      }
    }

    .site-nav__inner {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100%;
      background-color: $beige;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      opacity: 0;
      transition: 0.25s opacity ease-out;
      border-top: rem-calc(1) solid $white;

      @include respond-max($bp-mob-max) {
        overflow-y: scroll;
      }

      @include respond($bp-phab) {
        width: 50vw;
        left: 50vw;
      }

      @include respond($bp-md) {
        width: auto;
        height: auto;
        left: unset;
        top: unset;
        position: relative;
        align-items: center;
        flex-direction: row;
        transform: translateX(0);
        opacity: 1;
        border-top: none;
      }
    }

    .text-button {
      font-size: rem-calc(12);
      text-transform: uppercase;
      font-family: $primary-font;
      font-weight: $medium;
      background-color: $peach;
      padding: rem-calc(9) rem-calc(15) rem-calc(9);
      border-radius: rem-calc(15);
      max-width: rem-calc(152);
      color: $white;
      min-width: unset;

      @include respond($bp-md) {
        margin-left: rem-calc(12);
      }
    }

    .nav-list-container {
      display: flex;
      flex-direction: column;
      padding: 1rem rem-calc(24);

      @include respond($bp-md) {
        padding: 0 0;
        flex-direction: row;
        align-items: center;
      }
    }

    .nav-list {
      display: flex;
      flex-direction: column;
      margin-bottom: rem-calc(24);

      @include respond($bp-md) {
        flex-direction: row;
        padding: 0 0;
        margin-bottom: 0;
      }

      li.--with-submenu {

        & > a {
          font-weight: $bold;
          position: relative;

          &::after {
            content: "";
            width: rem-calc(6);
            height: rem-calc(6);
            border-width: rem-calc(1) rem-calc(1) 0 0;
            border-color: $peach;
            border-style: solid;
            position: absolute;
            bottom: rem-calc(6);
            left: 50%;
            transform: translateX(-50%) rotate(135deg);
            display: none;

            @include respond($bp-md) {
              display: block;
            }
          }
        }

        &:hover {
          .sub-nav {
            @include respond($bp-md) {
              transform: translateY(0);
              opacity: 1;
              pointer-events: auto;
            }
          }
        }

        @include respond($bp-md) {
          display: flex;
          flex-direction: row;
          position: relative;
        }

        .sub-nav {

          @include respond($bp-md) {
            position: absolute;
            top: 100%;
            left: 0;
            background-color: $white;
            box-shadow: 0 0 rem-calc(16) 0 $box-shadow;
            border-radius: rem-calc(15);
            padding: 1rem rem-calc(63) 1rem 1rem;
            opacity: 0;
            transform: translateY(-20%);
            pointer-events: none;
            transition: 0.25s opacity ease-out, 0.25s transform ease-out;
          }


          li {
            a {
              @include respond($bp-md) {
                white-space: nowrap;
                padding: 0 0;
                margin-bottom: rem-calc(8);
              }
            }

            &:last-child a {
              @include respond($bp-md) {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      li a {
        text-transform: uppercase;
        color: $peach;
        font-size: rem-calc(12);
        display: block;
        padding: 1rem 0;
        border-bottom: rem-calc(1) solid $white;
        text-decoration: none;

        @include respond($bp-md) {
          border-bottom: none;
          padding: rem-calc(12) rem-calc(12);
        }
      }
    }
  }

  .mobile-nav__footer {
    padding: rem-calc(24);
    background: url('../../images/green-texture.jpg') no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;

    @include respond($bp-md) {
      display: none;
    }

    .social-buttons {
      display: flex;
      margin-bottom: 1rem;

      .social-btn {
        display: block;
        width: rem-calc(20);
        height: rem-calc(20);
        margin-right: rem-calc(8);
      }
    }

    .copyright {
      color: $white;
      font-size: rem-calc(12);
      font-family: $primary-font;
      margin-bottom: rem-calc(8);
    }

    .legal-btns {
      display: flex;

      .legal-btn {
        font-size: rem-calc(12);
        text-decoration: none;
        font-family: $primary-font;
        font-weight: 300;
        color: $peach;
        margin-right: rem-calc(8);
      }
    }
  }

  .library-nav {
    position: relative;

    &:hover {
      .nav-dropdown {
        transform: translateY(0);
        opacity: 1;
        pointer-events: auto;
      }
    }

    .menu-title {
      font-size: rem-calc(20);
      color: $peach;
      padding: rem-calc(11) rem-calc(24) rem-calc(17);
      position: relative;
      display: block;
      cursor: pointer;

      &::before,
      &::after {
        @include respond-max($bp-mob-max) {
          display: none;
        }
      }

      &::before {
        content: "\/";
        position: absolute;
        left: rem-calc(2);
        font-size: rem-calc(32);
        color: $black;
        font-weight: 200;
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        content: "";
        position: absolute;
        bottom: rem-calc(3);
        width: rem-calc(8);
        height: rem-calc(8);
        border-width: rem-calc(1) rem-calc(1) 0 0;
        border-color: $peach;
        border-style: solid;
        left: 50%;
        transform: translateX(-50%) rotate(135deg);
      }
    }

    .nav-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      min-width: rem-calc(300);
      width: auto;
      background-color: $white;
      border-radius: rem-calc(15);
      box-shadow: 0 0 rem-calc(16) 0 $box-shadow;
      padding: rem-calc(24) rem-calc(20);
      transform: translateY(-20%);
      opacity: 0;
      transition: 0.25s transform ease-out, 0.25s opacity ease-out;
      pointer-events: none;

      .nav-list {
        display: flex;
        flex-direction: column;

        &.prismic-nav {
          margin-bottom: 1rem;
          border-bottom: rem-calc(1) solid $border;
        }


        &.app-nav {
          .nav-list-item:last-child {
            margin-bottom: 0;
          }
        }

        .nav-list-item {
          margin-bottom: 1rem;
          a,
          div {
            text-decoration: none;
            text-transform: uppercase;
            color: $peach;
            display: block;
            line-height: 1;
            cursor: pointer;
            font-family: $primary-font;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .nav-list.top-level {
    display: flex;
    flex-direction: row;

    .nav-list-item {
      a {
        display: block;
        padding: rem-calc(11) rem-calc(24);
        text-decoration: none;
        color: $peach;
        text-transform: uppercase;
        font-family: $primary-font;

        &.active {
          font-weight: $bold;
        }
      }
    }
  }

  .app-header--signed-in {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: $header-height-mobile;

    @include respond($bp-md) {
      padding: rem-calc(15) 0;
      height: $header-height;
    }

    .mobile-col {
      display: flex;
      align-items: center;
      @include respond($bp-md) {
        display: none;
      }

      a {
        display: block;
      }
    }

    .site-title {
      font-size: rem-calc(17);
      font-weight: 300;
      color: $peach;
      font-family: $primary-font;
      padding-left: 1rem;
      position: relative;
      padding-bottom: rem-calc(3);

      &::before {
        content: "\/";
        position: absolute;
        left: rem-calc(2);
        top: 50%;
        transform: translateY(-50%);
        color: $black;
        font-size: rem-calc(30);
        display: none;

        @include respond($bp-phab) {
          display: block;
        }
      }
    }

    .mob-logo {
      @include respond($bp-phab) {
        display: none;
      }
    }

    .tablet-logo {
      display: none;
      @include respond($bp-phab) {
        display: block;
      }
    }

    .header-col {
      display: none;

      @include respond($bp-md) {
        display: flex;
        align-items: center;
      }
    }

    .col-right {
      display: none;
      @include respond($bp-md) {
        display: flex;
      }
    }
  }

  .mobile-nav {
    position: fixed;
    top: $header-height-mobile;
    height: calc(100lvh - #{$header-height-mobile});
    width: 100vw;
    left: 0;
    border-top: rem-calc(1) solid $white;
    pointer-events: none;

    &.active {
      pointer-events: auto;

      .mobile-nav__inner {
        transform: translateX(0);
        opacity: 1;
      }

      .green-bg {
        opacity: 1;
      }
    }

    @include respond($bp-md) {
      display: none;
    }

    .green-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: rgba($site-dk-green, 0.84);
      z-index: 3;
      opacity: 0;
      transition: 0.25s opacity ease-out;
    }

    .mobile-nav__inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $beige;
      z-index: 4;
      transform: translateX(20%);
      opacity: 0;
      transition: 0.25s opacity ease-out, 0.25s transform ease-out;

      @include respond($bp-phab) {
        width: 50vw;
        left: 50vw;
      }

      .mobile-nav-list {
        display: flex;
        flex-direction: column;
        padding: rem-calc(17) rem-calc(24) 1rem;

        .nav-list-item {
          border-bottom: rem-calc(1) solid $white;

          a,
          div {
            color: $peach;
            font-family: $primary-font;
            font-weight: 400;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: none;
            padding: 1rem 0;
            display: block;
          }
        }
      }


    }
  }
}

.nav-list-item.--run-tutorial {
  display: none;
}

body.sequence-builder-v2 {
  main.no-scroll.layout {
    position: unset;
  }

  .nav-list-item.--run-tutorial {
    display: inherit;
  }
}

