@import '../../styles/util/util';


.multi-value-text-input {
  width: 100%;

  label {
    width: 100%;
  }

  .label-text {
    margin-bottom: 0.5rem;
    display: block;
    text-align: left;
  }

  .value-item-container {
    display: flex;
    overflow-x: scroll;

    .value-item {
      padding: 0 0.3rem 0 1rem;
      background-color: rgba(255,255,255,0.4);
      max-width: 12rem;
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      box-shadow: $input-box-shadow;

      .value {
        white-space: nowrap;
        width: calc(100% - #{rem-calc(10)});
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: rem-calc(12);
      }

      .delete-button {
        width: rem-calc(20);
        cursor: pointer;
        text-align: center;
        font-size: 2rem;
        margin-left: rem-calc(10);
      }
    }
  }
}

.chips-input-wrapper {
  padding: rem-calc(8) rem-calc(8) rem-calc(25);
  border: 1px solid #e4e4e4;
  box-shadow: 0 0.25rem 0.25rem 0 rgba(194, 194, 194, 0.01);

  input[type=text].input.no-chips {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    border: 0px solid #ffffff;
    box-shadow: none;

    &:focus {
      outline: none;
    }
  }

  &.disabled {
    background-color: #e4e8ea;
    pointer-events: none;

    input[type=text] {
      background-color: #e4e8ea;
      opacity: 0;
    }
  }

  .value-item-container{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: rem-calc(24);

    &>.value-item {
      flex: 1 1 auto;
    }

    input[type=text].input.value-input-field,
    input[type=text].input.no-chips {
      flex: 1 1 auto;
      width: auto;
      border: 0px solid #ffffff;
      box-shadow: none;

      &:focus {
        outline: none;
      }
    }
  }
}
