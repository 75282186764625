@import './styles/app';

* {
  box-sizing: border-box;
}

// html {
//   font-size: 100%;
//   background: #000;
// }

// body {
//   font-size: 1rem;
//   min-height: 100vh;
//   background: {
//     color: $black;
//     image: $login-gradient-bg, url('./images/login_bg.png');
//     size: cover;
//     repeat: no-repeat;
//     position: center center;
//   }

//   &.no-bkg-img {
//     background-image: $black-gradient-bg;
//     background-color: $black;
//   }
// }

// #force-font-loading {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   opacity: 0;
//   font-family: 'Poppins';

//   .force-font-loading-Poppins-ExtraBold {
//     font-weight: $extra-bold;
//   }

//   .force-font-loading-Poppins-Light {
//     font-weight: $light;
//   }

//   .force-font-loading-Poppins-Medium {
//     font-weight: $medium;
//   }

//   .force-font-loading-Poppins-Regular {
//     font-weight: $regular;
//   }
// }

.layout.please-wait {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    margin: 0 auto 1rem !important;
  }
}
