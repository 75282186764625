@import '../../styles/util/util';

.sort-th {
  cursor: pointer;

  &.dir-asc {
    p.body.body--small {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  p.body.body--small {
    position: relative;
    display: flex;
    align-items: center;

    &.text-center {
      justify-content: center;
    }

    svg {
      margin-left: rem-calc(7.5);
      display: none;
    }
    
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: $dk-green;
      position: absolute;
      top: calc(100% + 0.5rem);
      left: 0;
      opacity: 0;
      width: 0;
      transition: 0.25s width ease-out;
    }

    &.active {
      font-weight: $bold;
      font-size: rem-calc(16);
      color: $dk-green;

      &::after {
        opacity: 1;
        width: 100%;
      }

      svg {
        display: block;
      }
    }
  }
}