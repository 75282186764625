.error-notification {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 200px;

  &__inner {
    background-color: red;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    padding: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .close-error {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}