@import '../../styles/util/util';

.browser-too-small {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $peach;
  z-index: 998;

  @include respond(768px) {
    display: none;
    pointer-events: none;
  }

  .turn-device,
  .larger-device {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    overflow-y: scroll;
    padding-top: rem-calc(200);
  }

  .turn-device {
    display: none;

    @media (min-height: 768px) {
      display: flex;
    }
  }

  .larger-device {

    @include respond-max(768px) {
      display: flex;
    }

    @media (min-height: 768px) {
      display: none;
    }
  }

  .browser-too-small__message {

    .h2 {
      text-transform: initial;
      margin-bottom: rem-calc(18);
      max-width: rem-calc(280);
    }

    svg {
      width: 100%;
      height: auto;
      max-width: rem-calc(280);
    }

    .buttons-container {
      display: flex;
      flex-direction: column;
      max-width: rem-calc(250);
      margin: 0 auto 5.1875rem;

      .text-button {
        margin-top: rem-calc(30);
      }
    }
  }
}
