@import '../../styles/util/util';

.layout {
  background-image: url('../../images/texture-page@2x.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: calc(100lvh - #{$header-height-mobile});
  width: 100vw;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;

  @include respond($bp-md) {
    height: calc(100lvh - #{$header-height});
  }

  &.bg-collection {
    background-image: url('../../images/texture-collection-page@2x.jpg');
  }

  &.modal-layout {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}